<template>
  <div id="divAboutAppWrapper" class="about-us-wrapper">
    <div class="about-us-body">
      <VueSpacer level="70"></VueSpacer>
      <img src="@/assets/logos/pmaktif.png" alt="kanalaktif" class="pmaktif-logo" />
      <VueSpacer level="20"></VueSpacer>
      <!-- <div class="app-static-content" v-html="content"></div> -->
      <div>v {{ getVersion }}</div>
    </div>
    <div class="about-us-footer">
      <BrandLogo
        :hasBorder="false"
        :width="160"
        :height="90"
        :image="require('@/assets/logos/philip-morris-logo.png')"
      />

      <VueText sizeLevel="4" weightLevel="1">PM Aktif bir Philip Morris kuruluşudur.</VueText>
    </div>
  </div>
</template>
<script>
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import { version } from '@/../package.json';

// import StaticContent from '@/services/Api/staticContents';

export default {
  name: 'AboutApp',
  components: { VueText, VueSpacer, BrandLogo },
  data() {
    return {
      content: null,
    };
  },
  // created() {
  //   StaticContent.getStaticContent('UygulamaHakkinda').then(res => {
  //     if (res.data.Data) {
  //       this.content = res.data.Data.content;
  //     }
  //   });
  // },
  computed: {
    getVersion() {
      return version;
    },
  },
};
</script>
<style scoped lang="scss">
.about-us-wrapper {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: palette-space-level('30');
  position: relative;
  min-height: calc(100vh - 180px);
  .app-static-content {
    max-height: 300px;
    overflow: auto;
  }
  // .about-us-footer {
  //   width: 100%;
  //   position: absolute;
  //   bottom: 0;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
  .about-us-body,
  .about-us-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    p {
      padding-top: palette-space-level(10);
    }
  }
  .pmaktif-logo {
    width: 200px;
  }
}
</style>
